<template>
  <div>
    <div class="success-box">
      <div class="success-box">
        <div class="success-box-icon">
          <svg-icon icon-class="success" className="success-svg" />
        </div>
        <div class="notice">提交成功</div>
        <div class="content">感谢你提出的宝贵意见~</div>
      </div>
    </div>
  </div>
</template>


<script >
export default {
  data() {
    return {
      successTitle: "意见反馈",
    };
  },
  components: {
  },
  methods: {},
};
</script>

<style scoped>
.success-svg {
  width: 100%;
  height: 100%;
}
.success-box {
  width: 100vw;
  text-align: center;
}
.notice {
  width: 100vw;
  height: 20px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  line-height: 20px;
  text-align: center;
}
.content {
  width: 100vw;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
  line-height: 20px;
  text-align: center;
}
.success-box-icon {
  width: 150px;
  height: 144px;
  margin: 79px auto;
}
.success-box {
  width: 100%;
}
</style>
